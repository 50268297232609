<template>
	<div class="games">
		<!--Principal-->
		<section id="home-background" class="main-game pt-5">
				<div class="container" style="min-height:450px;">
						<div class="row">
								<div class="col-lg-7">
										<div class="p-4"></div>
										<h2 class="w3-text-white w3-jumbo"><span class="backspan">DarkStory 2D Online</span></h2>
										<h4 class="w3-text-white"><span class="backspan">The entertaining ORPG addictive and wonderful</span></h4>
										<h4 class="w3-text-white"><span class="backspan">Available now for <span style="color: #7cc1ff; font-weight: bold;">PC</span></span></h4>
										<div class="p-4"></div>
										
										<a href="https://darkstory2d.com/download.php" class="w3-button w3-blue w3-hover-light-gray p-3">Download!</a>
										<a href="javascript:void(0)"><img src="@/assets/img/steamlogo.png" class="w3-image" style="width:60px;" /></a>                
										<div class="p-4"></div>
								</div>
						</div>
				</div>
		</section>
		<!-- END Principal-->
		<section class="w3-white pt-5 pb-5">
    <div class="row">
        <div class="w3-col l12 m12 w3-light-gray">
            <div class="text-center">
                <figure style="max-width:150px; margin:0 auto;"><img src="https://bravewolfdev.com/main/assets/img/logo_lobo.png" class="w3-image" /></figure>
            </div>
            <h1 class="w3-center bw-fonts-archivo-black" style="font-style: italic;">BraveWolf Games</h1>
        </div>
    </div>
		<!--START GALLERY-->
    <div class="container container-portafolio" data-ref="container">
        <div class="controls" style="border-radius:1em; border-top-left-radius: 10px; border-top-right-radius: 10px;">
            <button type="button" class="btn btn-secondary" data-filter="all">All</button>
            <button type="button" class="btn btn-primary" data-filter=".game-pc">PC</button>

            <button type="button" class="control ml-1" data-sort="default:asc">Asc</button>
            <button type="button" class="control" data-sort="default:desc">Desc</button>

            <input type="text" class="input" v-on:keyup="SearchActionEvent" data-ref="input-search" placeholder="Buscar por nombre"/>
        </div>
        <div class="p-2"></div>
        <div class="mix game-pc">
            <div class="item pl-2 pr-2 text-center">
                <figure class="d-inline-block m-0 " style="box-shadow: 1px 10px 10px #091c31; overflow:hidden" >
                    <a href="#lbn"><img src="@/assets/img/g1.jpg" class="w3-round w3-image zoombackground" /></a>
                </figure>
            </div>
        </div>
        <div class="mix game-pc">
            <div class="item pl-2 pr-2 text-center">
                <figure class="d-inline-block m-0 " style="box-shadow: 1px 10px 10px #091c31; overflow:hidden" >
                    <a href="#ih"><img src="@/assets/img/g2.jpg" class="w3-round w3-image zoombackground" /></a>
                </figure>
            </div>
        </div>
        <div class="mix game-pc">
            <div class="item pl-2 pr-2 text-center">
                <figure class="d-inline-block m-0 " style="box-shadow: 1px 10px 10px #091c31; overflow:hidden" >
                    <a href="#dso"><img src="@/assets/img/g3.jpg" class="w3-round w3-image zoombackground" /></a>
                </figure>
            </div>
        </div>
        <!-- <div class="mix movil-app "></div>
        <div class="mix desktop-app "></div>
        <div class="mix movil-app "></div>
        <div class="mix desktop-app "></div>
        <div class="mix video-game "></div> -->

        <div class="gap"></div>
        <div class="gap"></div>
        <div class="gap"></div>
    </div>
		<!--END GALLERY-->

    
</section>
		<!--START FOOTER GAME-->
		<section class="foot-game">
			<div class="p-4"></div>
			<div class="container text-center">
				<div class="row justify-content-center">
						<div class="col-sm-5">
								<h1>Want to know more?</h1>
								<h4>For further information don't hesitate to contact us. We would be happy to provide you with more information.</h4>
								<router-link to="/contact" class="btn btn-primary">Contact us</router-link>
						</div>
				</div>
			</div>
			<div class="p-4"></div>
		</section>
		<!--END FOOTER GAME-->
	</div>
</template>

<script>
import '@/assets/css/stylegallery.css'
import mixitup from 'mixitup'

export default {
    name: "Games",
		components: {},
		data(){
        return {
					mixer: null,
					keyupTimeout:null
        }
    },
		methods:{
			events(){
				console.log("simple")
			},
			SearchActionEvent(){
				const inputSearch = document.querySelector('[data-ref="input-search"]')
				var searchValue;

        if (inputSearch.value.length < 3) {
            // If the input value is less than 3 characters, don't send

            searchValue = '';
        } else {
            searchValue = inputSearch.value.toLowerCase().trim();
        }

        // Very basic throttling to prevent mixer thrashing. Only search
        // once 350ms has passed since the last keyup event

        clearTimeout(this.keyupTimeout);

        this.keyupTimeout = setTimeout(($this) => {
					$this.filterByString(searchValue);
        }, 350,this);
			},
			filterByString(searchValue){
				try{
					if (searchValue) {
						this.mixer.filter('[class*="' + searchValue + '"]');
					} else {
						this.mixer.filter('all');
					}
				}catch(error){
					console.error("Error on filterByString: ",error)
				}
			}
		},
    mounted(){
			const containerEl = document.querySelector('[data-ref="container"]')
			const inputSearch = document.querySelector('[data-ref="input-search"]')

			this.mixer = mixitup(containerEl,{
				animation: { duration: 350 },
				callbacks: {
					onMixClick: function() {
						// Reset the search if a filter is clicked
						if (this.matches('[data-filter]')) {
								inputSearch.value = '';
						}
					}
        }
			});
    }
}
</script>

<style>
.main-game { background:url('../assets/img/toXSHRr2.jpg'); background-size:cover; background-repeat:no-repeat; max-height: 574px; position:relative;}
.main-game .container { position:relative; z-index:1; }
.main-game .backspan { background:rgba(88, 88, 88, 0.2); }
.main-game::after{ content: ''; background: #1d1d1f52; position: absolute; width: 100%; height: 100%; top: 0; max-height: 574px }
.foot-game { background:url('../assets/img/833.jpg'); border-top:2px solid black; }
</style>